import React from "react"
import KbusWrapper from "./src/components/kbusWrapper";

export const wrapPageElement = ({ element, props }) => (
  <KbusWrapper {...props}>{element}</KbusWrapper>
);

export const onServiceWorkerUpdateReady = () => {
  console.log("Reload");
  window.location.reload(true);
}

export const onClientEntry = () => {
  console.log(
    "%c########"+"%c@@@@@@@@@@@"+"%c%#######"+"%c@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@\n" +
    "%c########"+"%c@@@@@@@@@@@"+"%c%#######"+"%c@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@\n" +
    "%c########"+"%c@@@@@@@@@@@"+"%c%#######"+"%c@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@\n" +
    "%c########"+"%c@@@@@@"+"%c#####"+"%c########"+"%c@"+"%c&########&"+"%c@@@@"+"%c#######&"+"%c@@@@@@"+"%c#######&"+"%c@@"+"%c&############"+"%c@\n" +
    "%c########"+"%c@@@"+"%c%#######"+"%c######################"+"%c@"+"%c#######&"+"%c@@@@@@"+"%c#######&##############"+"%c@@\n" +
    "%c########"+"%c@"+"%c##########"+"%c##############################&"+"%c@@@@@@"+"%c#######%#######"+"%c@@@@@@@@@\n" +
    "%c################"+"%c@@@"+"%c########&"+"%c@@@@@@"+"%c&##############&"+"%c@@@@@@"+"%c#######&##############"+"%c@@\n" +
    "%c################"+"%c@@@"+"%c########"+"%c@@@@@@@@"+"%c##############&"+"%c@@@@@@"+"%c#######&"+"%c@@"+"%c##############\n" +
    "%c########%##########"+"%c#########"+"%c@@@@@@"+"%c################"+"%c@@@@@"+"%c########"+"%c&@#@@@@@@&"+"%c#######\n" +
    "%c########"+"%c@@@"+"%c########"+"%c######################"+"%c@"+"%c#####################"+"%c&"+"%c###############(\n" +
    "%c#######"+"%c@@@@@"+"%c#######"+"%c####################"+"%c@@@@@"+"%c#################"+"%c@&"+"%c##############&"+"%c@@\n",
    'color: #129540', 'color: #fff', 'color: #BB2218', 'color: #fff',
    'color: #129540', 'color: #fff', 'color: #BB2218', 'color: #fff',
    'color: #129540', 'color: #fff', 'color: #BB2218', 'color: #fff',
    'color: #129540', 'color: #fff', 'color: #129540', 'color: #BB2218', 'color: #fff', 'color: #BB2218', 'color: #fff', 'color: #BB2218', 'color: #fff', 'color: #BB2218', 'color: #fff', 'color: #BB2218','color: #fff',
    'color: #129540', 'color: #fff', 'color: #129540', 'color: #BB2218', 'color: #fff', 'color: #BB2218', 'color: #fff', 'color: #BB2218', 'color: #fff',
    'color: #129540', 'color: #fff', 'color: #129540', 'color: #BB2218', 'color: #fff', 'color: #BB2218', 'color: #fff',
    'color: #129540', 'color: #fff', 'color: #BB2218', 'color: #fff', 'color: #BB2218', 'color: #fff','color: #BB2218', 'color: #fff',
    'color: #129540', 'color: #fff', 'color: #BB2218', 'color: #fff', 'color: #BB2218', 'color: #fff','color: #BB2218', 'color: #fff', 'color: #BB2218',
    'color: #129540', 'color: #BB2218', 'color: #fff', 'color: #BB2218', 'color: #fff','color: #BB2218', 'color: #fff','color: #BB2218',
    'color: #129540', 'color: #fff', 'color: #129540', 'color: #BB2218', 'color: #fff', 'color: #BB2218','color: #fff', 'color: #BB2218',
    'color: #129540', 'color: #fff', 'color: #129540', 'color: #BB2218', 'color: #fff', 'color: #BB2218','color: #fff', 'color: #BB2218','color: #fff'
  )
}