import React, {useState} from 'react'
import useCookie from "./useCookie";

export const LangContext = React.createContext();

const KbusWrapper = ({ children }) => {

  const [cookie, updateCookie] = useCookie('active-lang');
  const [activeLang, setActiveLang] = useState(['esp', 'eus'].indexOf(cookie) > -1 ? cookie : 'eus');

  const changeLang = (lang) => {
    updateCookie(lang);
    setActiveLang(lang)
  }

  const translate = (data, collection, field) => {
    const _collection = data[`${collection}${activeLang === 'eus' ? '_eus' : ''}`]
    return field.length > 0 ? _collection[field] : _collection
  }

  const noDisponible = () => {
    return activeLang === 'eus' ?
      'Ez dago erabilgarri' :
      'Servicio no disponible'
  }

  return (
    <LangContext.Provider value={{
      activeLang,
      changeLang: changeLang,
      translate: translate,
      noDisponible: noDisponible
    }}>
      {children}
    </LangContext.Provider>
  );
}

export default KbusWrapper;